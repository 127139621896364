import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faEnvelope,
    faEllipsis,
    faThumbsUp,
    faThumbsDown,
    faX,
    faCommentDots
} from '@fortawesome/free-solid-svg-icons';
import { convertFromRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import './Shift.css';

type Props = {
    gridData: any
    colIndex: number
    rowIndex: number
    day: any
    flippedCells: any
}

const Shift = ({gridData, colIndex, rowIndex, day, flippedCells}: Props) => {
    const [shiftStatus, setShiftStatus] = useState<any>('')

    const getBackgroundColor = (shiftStatus: number) => {
        switch(shiftStatus){
            case 0:
              return '#F7DE3A';
            case 1:
              return '#74c365';
            case 2:
              return '#FF0000'
        }
    };

    const getShiftStatus = (shiftStatus: number) => {
        switch(shiftStatus) {
            case 0: 
              return 'Pending'
            case 1:
              return 'Sent'
            case 2:
              return 'Canceled'
        }
    }

    const getTStatus = (tStatus: number) => {
        switch(tStatus) {
            case 0: 
              return ''
            case 1:
              return <FontAwesomeIcon icon={faThumbsUp} />
            case 2:
              return <FontAwesomeIcon icon={faThumbsDown}/>
        }
    }

    const getTStatusColor = (tStatus: number) => {
        switch(tStatus) {
            case 0: 
              return 'white'
            case 1:
              return '#74c365'
            case 2:
              return 'red'
        }
    }

    const getSStatus = (sStatus: number) => {
        switch(sStatus) {
            case 0: 
              return ''
            case 1:
              return <FontAwesomeIcon icon={faCheck} />
            case 2:
              return <FontAwesomeIcon icon={faX}/>
            case 3:
              return <FontAwesomeIcon icon={faEnvelope}/>
        }
    }

    const getSStatusColor = (sStatus: number) => {
        switch(sStatus){
            case 0:
              return 'white';
            case 1:
              return '#74c365';
            case 2:
              return 'red';
            case 3:
              return '#f7de3a'
        }
    }

    const getHStatus = (hStatus: number) => {
        switch(hStatus) {
            case 0: 
              return 'white'
            case 1:
              return '#ffc0cb'
            case 2:
              return '#90ee90'
            case 3:
              return '#FF0000'
        }
    }

    useEffect(() => {
        setShiftStatus(getShiftStatus(gridData[`${colIndex}-${rowIndex}`].shiftStatus))
    })

    const shiftStyle = {
        backgroundColor: getBackgroundColor(gridData[`${colIndex}-${rowIndex}`].shiftStatus)
    };

    const renderSavedContent = () => {
        try {
            const contentState = convertFromRaw(JSON.parse(gridData[`${colIndex}-${rowIndex}`].notesJson));
            const editorState = EditorState.createWithContent(contentState);
    
            const customStyleMap = {
                inlineStyles: {
                    HIGHLIGHT: { 
                        style: { backgroundColor: 'yellow' }
                    }
                }
              };
              
            const html = stateToHTML(editorState.getCurrentContent(), customStyleMap);
            return <div dangerouslySetInnerHTML={{ __html: html }} />;
        } catch (error) {
            return <div></div>;
        }
    };

    return (
        <>
            <div className="flip-container">
                <div className={`flip-shift ${flippedCells[`${colIndex}-${rowIndex}`] ? 'flip' : ''}`}>
                    <div className="flip-shift-front">
                        <div 
                            className="shift-details-table"
                            style={shiftStyle}
                        >
                            <div className="details-data">
                                WhatsApp Name {gridData[`${colIndex}-${rowIndex}`].wp} - {day.date}
                            </div>
                            <div className="details-data centered" style={{display: 'flex', justifyContent: 'space-around'}}>
                                <div>Required Workers: {gridData[`${colIndex}-${rowIndex}`].requiredWorkers}</div>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>Status: {shiftStatus} {' '}
                                    {gridData[`${colIndex}-${rowIndex}`].notes === null ? (null) : (
                                        gridData[`${colIndex}-${rowIndex}`].notes === "null" ? (null) :(
                                            <FontAwesomeIcon icon={faCommentDots} style={{marginLeft: 20}}/>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="details-data">Client</div>
                            <div className="details-data centered">
                                {gridData[`${colIndex}-${rowIndex}`].client}
                            </div>
                            <div className="details-data">Client Site Contact:</div>
                            <div className="details-data centered">
                                {gridData[`${colIndex}-${rowIndex}`].clientSiteContacts}
                            </div>
                            <div className="details-data">PACE On Call Number:</div>
                            <div className="details-data centered">
                                {gridData[`${colIndex}-${rowIndex}`].onCallNumber}
                            </div>
                            <div className="details-data" style={{ borderBottom: 0 }}>
                                Project
                            </div>
                            <div className="details-data centered" style={{ borderBottom: 0 }}>
                                {gridData[`${colIndex}-${rowIndex}`].project}
                            </div>
                        </div>
                        <div className="time-table">
                            <div className="time-table-header">Start Time</div>
                            <div className="time-table-header">Finish Time</div>
                            <div className="time-table-header">Hours</div>
                            <div className="time-table-header">Job Number</div>
                            <div className="time-table-header">Labour order nr</div>
                            <div className="time-table-header">Invoice</div>
                            <div className="time-table-data" style={shiftStyle}>
                                {gridData[`${colIndex}-${rowIndex}`].startTime}
                            </div>
                            <div className="time-table-data" style={shiftStyle}>
                                {gridData[`${colIndex}-${rowIndex}`].endTime}
                            </div>
                            <div className="time-table-data" style={shiftStyle}>
                                {gridData[`${colIndex}-${rowIndex}`].hours}
                            </div>
                            <div className="time-table-data" style={shiftStyle}>
                                {gridData[`${colIndex}-${rowIndex}`].jobNumber}
                            </div>
                            <div className="time-table-data" style={shiftStyle}>
                                {gridData[`${colIndex}-${rowIndex}`].labourOrderNR}
                            </div>
                            <div className="time-table-data" style={shiftStyle}>
                                {gridData[`${colIndex}-${rowIndex}`].invoice}
                            </div>
                        </div>
                        <div className="worker-table">
                            <div className="worker-header">Role</div>
                            <div className="worker-header">Full Name</div>
                            <div className="worker-header">PTS</div>
                            <div className="worker-header">Number</div>
                            <div className="worker-header">T</div>
                            <div className="worker-header">S</div>
                            <div className="worker-header">F</div>
                            <div className="worker-header">D</div>
                            <div className="worker-header">H</div>
                            <div className="worker-header">Notes</div>
                            {gridData[`${colIndex}-${rowIndex}`].workers.map((worker: any) => (
                                <>
                                    <div className="worker-data" style={{backgroundColor: worker.colorIdentifier, textAlign: 'left'}}>{worker.workRole}</div>
                                    <div className="worker-data" style={{backgroundColor: worker.colorIdentifier, textAlign: 'left'}}>{worker.fullName}</div>
                                    <div className="worker-data" style={{backgroundColor: worker.colorIdentifier}}>{worker.pts}</div>
                                    <div className="worker-data" style={{backgroundColor: worker.colorIdentifier}}>{worker.phoneNumber}</div>
                                    <div className="worker-data" style={{backgroundColor: getTStatusColor(worker.t)}}>
                                        {getTStatus(worker.t)}
                                    </div>
                                    <div className="worker-data" style={{backgroundColor: getSStatusColor(worker.s)}}>
                                        {getSStatus(worker.s)}
                                    </div>
                                    <div className="worker-data" style={{backgroundColor: worker.f? '#74c365': 'white'}}>
                                        {worker.f? (
                                            <FontAwesomeIcon icon={faCheck}/>
                                        ): null}
                                    </div>
                                    <div className="worker-data" style={{backgroundColor: worker.d? '#87cefa': 'white'}}>
                                        {worker.d? (
                                            <FontAwesomeIcon icon={faCheck}/>
                                        ): null}
                                    </div>
                                    <div className="worker-data" style={{backgroundColor: getHStatus(worker.h_Color)}}></div>
                                    <div className="worker-data">
                                        {worker.notes? (
                                            <>
                                                <div className="show-notes">
                                                    <div className="notes-hover">
                                                    <FontAwesomeIcon icon={faEllipsis}/>
                                                    </div>
                                                    <div className="notes-shown">
                                                      {worker.notes}
                                                    </div>
                                                </div>
                                            </>
                                        ):(null)}
                                    </div>
                                </>
                            ))}
                        </div>
                        <div className="info-table">
                            <div className="info-header">
                                <div style={{fontWeight: 600}}>Location:&nbsp;</div>{gridData[`${colIndex}-${rowIndex}`].location}
                            </div>
                            <div className="info-data">
                                <div style={{fontWeight: 600}}>Post Code:&nbsp;</div>
                                {gridData[`${colIndex}-${rowIndex}`].postCode}
                            </div>
                            <div className="info-header">
                                <div style={{fontWeight: 600}}> Job Description:&nbsp;</div> {gridData[`${colIndex}-${rowIndex}`].description}
                            </div>
                            <div className="info-data" style={{ color: "red", fontWeight: 600 }}>
                                {gridData[`${colIndex}-${rowIndex}`].additionalInformation}
                            </div>
                            <div className="info-header">
                                <div style={{fontWeight: 600}}> Plants / Materials:&nbsp;</div> {gridData[`${colIndex}-${rowIndex}`].plants_Materials}
                            </div>
                            <div className="info-data">
                                <div style={{fontWeight: 600}}> Vehicle Registration:&nbsp;</div> {gridData[`${colIndex}-${rowIndex}`].vehicleRegistration}
                            </div>
                        </div>
                    </div>
                    <div className="flip-shift-back" style={{color: flippedCells[`${colIndex}-${rowIndex}`]? 'black': 'transparent'}}>
                        <div style={{fontSize: 23, fontWeight: 700}}>Notes</div>
                        {renderSavedContent()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shift;
