import React, { useEffect, useState } from "react";
import { TextField, Button, Select, MenuItem, Autocomplete } from "@mui/material";
import { useForm } from "react-hook-form";
import {
    editShift,
    getClientSitescontact,
    getPaceSiteContacts,
    getProjects,
    GetRegions
} from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";

type FormValues = {
    id: number
    week: number
    projectId: string
    rowIndex: number
    columnIndex: number
    whatsAppName: string
    regionId: string
    jobDate: string
    location: string
    postCode: string
    jobDescription: string
    shiftType: number
    requiredWorkers: number
    jobStatus: number
    startTime: any
    endTime: any
    hours: number
    clientSiteContact: any
    onCallNumber: any
    jobNumber: any
    labourOrderNR: any
    additionalInformation: any
    invoice: any
    plants_Materials: any
    vehicleRegistration: any
}

const EditShift = ({id, week, shiftData, dateOfTheShift, currentCell, getEditJobModal}: any) => {
    const {state} = useAuth();
    const [projects, setProjects] = useState([]);
    const [regions, setRegions] = useState<any>([]);
    const [paceSiteContacts, setPaceSiteContacts] = useState<any>([]);
    const [clientSiteContacts, setClientSiteContacts] = useState<any>([]);
    const [date] = useState<any>(shiftData.jobDate);

    const {register, handleSubmit, formState: {errors}} = useForm<FormValues>({
        defaultValues: {
            id: id,
            projectId: shiftData?.projectId,
            week: shiftData.week,
            rowIndex: currentCell.rowIndex,
            columnIndex: currentCell.colIndex,
            location: shiftData.location,
            regionId: shiftData.regionId,
            jobDate: format(shiftData.jobDate, 'dd/MM/yyyy'),
            shiftType: shiftData.shiftType,
            jobDescription: shiftData.jobDescription,
            requiredWorkers: shiftData.requiredWorkers,
            postCode: shiftData.postCode,
            whatsAppName: shiftData.whatsAppName,
            jobStatus: shiftData.jobStatus,
            startTime: shiftData.startTime.slice(11, 16),
            endTime: shiftData.endTime.slice(11, 16),
            hours: shiftData.hours,
            clientSiteContact: shiftData?.clientSiteContact,
            onCallNumber: shiftData?.onCallNumber,
            jobNumber: shiftData.jobNumber,
            labourOrderNR: shiftData.labourOrderNR,
            additionalInformation: shiftData.additionalInformation,
            invoice: shiftData.invoice,
            plants_Materials: shiftData.plants_Materials,
            vehicleRegistration: shiftData.vehicleRegistration,
        }
    })

    useEffect(() => {
        getProjects(state.user?.token).then(setProjects);
        GetRegions(state.user?.token).then(setRegions);
        getPaceSiteContacts(state.user?.token).then(setPaceSiteContacts);
        getClientSitescontact(state.user?.token).then(setClientSiteContacts);
    }, []);

    const onSubmit = async (data: any) => {
        data.startTime = format(new Date(`2024-01-01T${data.startTime}:00`), "yyyy-MM-dd'T'HH:mm:ss");
        data.endTime = format(new Date(`2024-01-01T${data.endTime}:00`), "yyyy-MM-dd'T'HH:mm:ss");
        data.jobDate = date;
        const response = await editShift(state.user?.token, data);
        getEditJobModal()
        if(response){
            toast.success("Updated Shift Successfully")
        }else{
            toast.error("Something went wrong!")
        }
    }

    return (
        <>  
            <form style={{ marginTop: "40px" }}>
                <div className="center-content">
                    <TextField
                        label="Week"
                        type="number"
                        {...register("week")}
                        size="small"
                        disabled
                        style={{ width: "7%", margin: 10 }} />
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        {...register("projectId", {
                            required: "Project is required"
                        })}
                        error={!!errors.projectId}
                        defaultValue={shiftData.projectId ? shiftData.projectId : 1}
                        size="small"
                        style={{ height: 40, margin: 10, width: '19.5%' }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            },
                        }}
                    >
                        <MenuItem disabled value={1}>Project</MenuItem>
                        {projects.map((project: any) => <MenuItem key={project.id} value={project.id}>
                            {project.company.companyName} - {project.projectName}
                        </MenuItem>
                        )}
                    </Select>
                    <TextField
                        label="WhatsApp Name"
                        type="text"
                        size="small"
                        error={!!errors.whatsAppName}
                        {...register("whatsAppName")}
                        style={{ width: "20%", margin: 10 }} />
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        error={!!errors.regionId}
                        defaultValue={shiftData.regionId ? shiftData.regionId : 1}
                        size="small"
                        {...register("regionId", {
                            required: "Region is required"
                        })}
                        style={{ height: 40, marginTop: 10, width: '20%', margin: 10 }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            },
                        }}
                    >
                        <MenuItem disabled value={1}>Region</MenuItem>
                        {regions.map((region: any) => <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                        )}
                    </Select>
                </div>
                <div className="center-content">
                    <TextField
                        label="Location"
                        type="text"
                        size="small"
                        error={!!errors.location}
                        {...register("location")}
                        style={{ width: "18%", margin: 10 }} />
                    <TextField
                        label="Post Code"
                        type="text"
                        size="small"
                        error={!!errors.postCode}
                        {...register("postCode")}
                        style={{ width: "17%", margin: 10 }} />
                    <TextField
                        label="Date"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        value={dateOfTheShift}
                        {...register("jobDate")}
                        disabled
                        size="small"
                        style={{ margin: 10, width: "11.5%" }} />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        defaultValue={shiftData.shiftType ? shiftData.shiftType : 0}
                        error={!!errors.shiftType}
                        {...register("shiftType", {
                            required: "Shift Type Workers is required"
                        })}
                        style={{ height: 40, marginTop: 10, margin: 10, width: '20%' }}
                    >
                        <MenuItem value={0}>Day Shift</MenuItem>
                        <MenuItem value={1}>Night Shift</MenuItem>
                        <MenuItem value={2}>Weekend</MenuItem>
                    </Select>
                </div>
                <div className="center-content">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        defaultValue={shiftData.jobStatus ? shiftData.jobStatus : 0}
                        error={!!errors.jobStatus}
                        {...register("jobStatus", {
                            required: "Shift Type Workers is required"
                        })}
                        style={{ height: 40, marginTop: 10, margin: 10, width: '20%' }}
                    >
                        <MenuItem value={0}>Pending</MenuItem>
                        <MenuItem value={1}>Sent</MenuItem>
                        <MenuItem value={2}>Canceled</MenuItem>
                    </Select>
                    <TextField
                        label="Start Time"
                        type="time"
                        size="small"
                        {...register("startTime")}
                        style={{ width: "15.5%", margin: 10 }} />
                    <TextField
                        label="End Time"
                        type="time"
                        size="small"
                        {...register("endTime")}
                        style={{ width: "15.5%", margin: 10 }} />
                    <TextField
                        label="Hours"
                        type="number"
                        size="small"
                        error={!!errors.hours}
                        {...register("hours")}
                        style={{ width: "15.5%", margin: 10 }} />
                </div>
                <div className="center-content">
                    <Autocomplete
                        size="small"
                        freeSolo
                        value={shiftData.clientSiteContact}
                        style={{ margin: 10, width: '20%' }}
                        options={clientSiteContacts}
                        getOptionLabel={(option: any) => `${option || ""}`}
                        {...register("clientSiteContact")}
                        renderInput={(params) =>
                            <TextField
                                label='Client Site Contact'
                                {...register("clientSiteContact")}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    onKeyDown: (event) => {
                                        if (event.key === ' ') {
                                            event.stopPropagation();
                                        }
                                    },
                                }}
                            />
                        }
                    />
                    <Autocomplete
                        size="small"
                        freeSolo
                        value={shiftData.onCallNumber}
                        style={{ margin: 10, width: '16.5%' }}
                        options={paceSiteContacts}
                        getOptionLabel={(option: any) => `${option || ""}`}
                        {...register("onCallNumber")}
                        renderInput={(params) =>
                            <TextField
                                label='Pace On Call Number'
                                {...register("onCallNumber")}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    onKeyDown: (event) => {
                                        if (event.key === ' ') {
                                            event.stopPropagation();
                                        }
                                    },
                                }}
                            />
                        }
                    />
                    <TextField
                        label="Job Number"
                        type="text"
                        size="small"
                        error={!!errors.jobNumber}
                        {...register("jobNumber")}
                        style={{ width: "13.5%", margin: 10 }} />
                    <TextField
                        label="Labour Order Nr"
                        type="number"
                        size="small"
                        error={!!errors.labourOrderNR}
                        {...register("labourOrderNR")}
                        style={{ width: "16.5%", margin: 10 }} />
                </div>
                <div className="center-content">
                    <TextField
                        label="Description"
                        multiline
                        rows={5}
                        size="small"
                        error={!!errors.jobDescription}
                        {...register("jobDescription")}
                        style={{ width: "35%", margin: 10 }} />
                    <TextField
                        label="Additional Information"
                        multiline
                        rows={5}
                        size="small"
                        error={!!errors.additionalInformation}
                        {...register("additionalInformation")}
                        style={{ width: "34.5%", margin: 10 }} />
                </div>
                <div className="center-content">
                    <TextField
                        label="Invoice"
                        type="text"
                        size="small"
                        error={!!errors.invoice}
                        {...register("invoice")}
                        style={{ width: "17%", margin: 10 }} />
                    <TextField
                        label="Plants / Materials"
                        type="text"
                        size="small"
                        error={!!errors.plants_Materials}
                        {...register("plants_Materials")}
                        style={{ width: "16.5%", margin: 10 }} />
                    <TextField
                        label="Vehicle Registration"
                        type="text"
                        size="small"
                        error={!!errors.vehicleRegistration}
                        {...register("vehicleRegistration")}
                        style={{ width: "16.5%", margin: 10 }} />
                    <TextField
                        label="Required Workers"
                        type="number"
                        size="small"
                        error={!!errors.requiredWorkers}
                        {...register("requiredWorkers")}
                        style={{ width: "16.5%", margin: 10 }} />
                </div>
                <div className="center-content margin40">
                    <Button
                        variant="outlined"
                        onClick={handleSubmit(onSubmit)}
                        style={{
                            marginRight: 20,
                            width: "25%",
                            backgroundColor: "#156bc2",
                            color: "white",
                        }}
                        size="large"
                    >
                        Save
                    </Button>
                </div>
            </form></>
    );
}

export default EditShift;
