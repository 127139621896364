import axios from "axios";

const apiUrl = 'https://railresourcing.api.pace-is.co.uk';

export const loginUser = async (payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/home/login`, payload)
        return response
    } catch (error: any) {
        return error?.response?.data.detail;
    }
}

export const getProjects = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetProjects`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const GetRegions = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetRegions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const addNewShift = async (token: any, payload: any) => {
    try {
        const response = await axios.post(
            `${apiUrl}/Resourcing/AddNewJob`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getWeeklyJobs = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyJobs?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getEditJobModal = async (token: any, id: string) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetEditJobModel?jobId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const editShift = async (token: any, payload: any) => {
    try{
        const response = await axios.post(`${apiUrl}/Resourcing/EditJob`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response
    }catch (error){
        return error
    }
}

export const editJobGridLocation = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/EditJobGridLocation`, payload,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getWorkersFromJob = async (token: any, id: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWorkersFromJob?jobId=${id}`,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const GetWorkersSuggestions = async (token: any, value: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/options/GetWorkersSuggestions?search=${value}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const deleteJob = async (token: any, id: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/Resourcing/DeleteJob?jobId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        console.log(error)
    }
}

export const getSignedInOptions = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetSignedInOptions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        return error
    }
}

export const getJobStatuses = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetJobStatuses`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        return error
    }
}

export const addNewWorkerToShift = async (token: any, jobId: any, orderNumber: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/AddNewWorkerToJob?jobId=${jobId}&orderNumber=${orderNumber}`, {},
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return error
    }
}

export const updateJobWorkerRows = async (token: any, payload: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/UpdateJobWorkerRows`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const removeWorkersFromJob = async (token: any, payload: any) => {
    try {
        const response = await axios.delete(`${apiUrl}/Resourcing/RemoveWorkersFromJob`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: payload 
        })
        return response
    } catch(error) {
        console.log(error)
    }
}

export const getClientsPieData = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/getClientsPieData?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getJobsStatusPieData = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/getJobsStatusPieData?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const getClientJobsStatistics = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/GetClientJobsStatistics?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}


export const getJobsLocations = async (token: any, week: any, year: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/getJobsLocations?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const changeWorkerRowColorIdentifier = async (token: any, JobRowsIds: any, color: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/ChangeWorkerRowColorIdentifier`, {
            JobRowsIds: JobRowsIds,
            Color: color
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const saveJobNotes = async (token: any, jobId: any, notes: any, notesJson: any) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/SaveJobNotes?jobId=${jobId}&notes=${notes}&notesJson=${notesJson}`, {} ,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const duplicateShift = async (token: any, jobId: any, jobDate: any, rowIndex: any, colIndex: any, ) => {
    try {
        const response = await axios.post(`${apiUrl}/Resourcing/DuplicateJob`, {
            jobId: jobId,
            jobDate: jobDate,
            rowIndex: rowIndex,
            columnIndex: colIndex,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const getPaceSiteContacts = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetSiteContacts`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getClientSitescontact = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetClientSitesContacts`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getPaymentMethod = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetPaymentMethods`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getWorkRolesSuggestions = async (token: any) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Options/GetWorkRolesSuggestions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getWeeklyWorkersData = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyWorkersData?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch(error) {
        console.log(error)
    }
}

export const getWeeklyOnCallDetails = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyOnCallDetails?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch(error) {
        console.log(error)
    }
}

export const getWeeklyWorkersList = async (token: any, week: number, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyWorkersList?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getWeeklyJobsDetailsForMap = async (token: any, week: any, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/GetWeeklyJobsDetailsForMap?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data
    }catch(error){
        console.log(error)
    }
}

export const getOrgChartDiagram = async (token: any, week: any, year: number) => {
    try {
        const response = await axios.get(`${apiUrl}/Statistics/GetOrgChartDiagram?week=${week}&year=${year}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const fatigueCheck = async (token: any, workerId: any, rowId: any) => {
    try {
        const response = await axios.get(`${apiUrl}/Resourcing/FatigueCheck?workerId=${workerId}&rowId=${rowId}`,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
