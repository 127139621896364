import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { getOrgChartDiagram } from "../../api/apiCall";
import { useAuth } from "../../auth/auth";
import { format } from "date-fns";
import "./OrgChart.css"

export const OrgChart = ({week, year}: any) => {
    const {state} = useAuth();
    const [orgChartData, setOrgChartData] = useState<any>(null);

    const getOrgChartData = async () => {
        const response = await getOrgChartDiagram(state.user?.token, week, year);
        console.log(response?.data.days, 'hell');
        setOrgChartData(response?.data)
    }
    useEffect(()=>{
        getOrgChartData()
    },[week, year]);

    return (
        <>
            {orgChartData? (
                <Tree
                    lineWidth={'3px'}
                    lineColor={'#021332'}
                    lineBorderRadius={'10px'}
                    label={
                        <div className="week">
                            {orgChartData?.week}
                        </div>
                    }
                >
                    {orgChartData?.days.map((day: any, index: any) => (
                        <TreeNode label={
                            <div className="day">
                                <div>{format(day.day, 'EEEE')}</div>
                                <div>{format(day.day, 'dd/MM/yyyy')}</div>
                            </div>
                        }>
                            {day.jobs.map((job: any) => (
                                <TreeNode label={
                                    <div className="day">{job.whatsAppName}</div>
                                }>
                                    {job.workers.map((worker: any) => (
                                        <TreeNode label={
                                            <div className="week worker">
                                                <div style={{fontWeight: 700}}>{worker.fullName}</div>
                                                <div style={{borderBottom: '1px solid gray', paddingBottom: 5}}>{worker.pts}</div>
                                                <div style={{marginTop: 10}}>{worker.workRole}</div>
                                                {worker.phoneNumber && worker.phoneNumber !== 'N/A'? (
                                                    <div className="phone-number">
                                                        <FontAwesomeIcon icon={faPhone} color={'#545454'} /> &nbsp;
                                                        {worker.phoneNumber}
                                                    </div>
                                                ):(null)}
                                            </div>
                                        }/>
                                    ))}
                                </TreeNode>
                            ))}
                        </TreeNode>
                    ))}
                </Tree>
            ): (null)}
        </>
    )
};
