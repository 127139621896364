import React, { useEffect, useState } from "react"
import L from "leaflet";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet"
import Header from "../../components/Header/Header";
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../auth/auth";
import { getJobsLocations, getWeeklyJobsDetailsForMap } from "../../api/apiCall";
import Modal from 'react-modal';
import './Maps.css'
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faHourglassEnd, faHourglassStart, faMaximize, faSignsPost, faUser } from "@fortawesome/free-solid-svg-icons";
import photo from '../../assets/map-photo.png'
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { format } from "date-fns";

const customIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 37],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const MapController = ({ coordinates, zoom }: { coordinates: [number, number], zoom: number }) => {
    const map = useMap();
    useEffect(() => {
        if (coordinates) {
            map.setView(coordinates, zoom);
        }
    }, [coordinates, map]);
    return null;
};

const Maps = () => {
    const {state} = useAuth()
    const [searchParams, setSearchParams] = useSearchParams();
    const [date, setDate] = useState();
    const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
    const [year, setYear] = useState<any>(searchParams.get('year') || 2024);
    const [positions, setpositions] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [shiftDetails, setshiftDetails] = useState<any>();
    const [coordinates, setCoordinates] = useState<any>([51.505, -0.09]);
    const [zoom, setZoom] = useState<number>(9)

    const getJobLocation = async () => {
        const response = await getJobsLocations(state.user?.token, week, year);
        setpositions(response);
    }

    const getJobsDetailsForMap = async () => {
        const response = await getWeeklyJobsDetailsForMap(state.user?.token, week, year);
        setshiftDetails(response.dailyJobsDetails)
    }

    const getShiftDetails = (shiftDetails: any) => {
        setOpen(true)
    }

    const getCoordinates = (latitude: any, longitude: any) => {
        setCoordinates([latitude, longitude]);
        setZoom(11);
    }

    useEffect(() => {
        getJobLocation();
        getJobsDetailsForMap();
        setSearchParams({ week, year });
    }, [date])

    return (
        <>
            <Header
                setDate={setDate}
                week={week}
                setWeek={setWeek}
                year={year}
                setYear={setYear}
            />
            <Modal
                isOpen={open}
                onRequestClose={() => {setOpen(false)}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className='modal-map'
                style={{
                    overlay: {
                        zIndex: 10000,
                        backgroundColor: 'transparent',
                        transition: 'none',
                        width: '100%',
                    }
                }}
            >
                <div style={{whiteSpace: 'pre-line'}}>
                    <img src={photo} style={{width: '400px', borderRadius: 10, border: '1px solid lightgray'}} />
                    <h3 style={{textAlign: 'center'}}>Allocated Teams</h3>
                    <Box sx={{ minHeight: 352, minWidth: 250 }}>
                        {shiftDetails?.map((details: any) => (
                            <SimpleTreeView key={details.day}>
                                <TreeItem itemId="day" label={format(details.day, 'EEEE')}>
                                    {details.jobDetails.map((shift: any, index: any) => (
                                        <TreeItem itemId={index} key={index} label={shift.whatsAppName} onClick={() => getCoordinates(shift.latitude, shift.longitude)}>
                                            <div>
                                                <h4>Project: </h4>
                                                <div>{shift.project}</div>
                                                <div className="line"></div>
                                                <h4>Details: </h4>
                                                <div>{shift.jobDescription ? shift.jobDescription : 'Civil Work'}</div>
                                                <div className="line marginBottom"></div>
                                                <div className="details">
                                                    <FontAwesomeIcon color='#1e3050' icon={faUser} />  
                                                    <div style={{ marginLeft: '17px' }}>
                                                        <div className="text">Workers</div>
                                                        <div>{shift.requiredWorkers}</div>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <FontAwesomeIcon color='#1e3050' icon={faCalendar} />  
                                                    <div style={{ marginLeft: '17px' }}>
                                                        <div className="text">Shift Date</div>
                                                        <div>{format(details.day, 'dd/MM/yyyy')}</div>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <FontAwesomeIcon color='#1e3050' icon={faHourglassStart} />  
                                                    <div style={{ marginLeft: '17px' }}>
                                                        <div className="text">Start Time</div>
                                                        <div>{format(shift.startTime, 'hh:mm')}</div>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <FontAwesomeIcon color='#1e3050' icon={faHourglassEnd} />  
                                                    <div style={{ marginLeft: '17px' }}>
                                                        <div className="text">End Time</div>
                                                        <div>{format(shift.endTime, 'hh:mm')}</div>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <FontAwesomeIcon color='#1e3050' icon={faSignsPost} />  
                                                    <div style={{ marginLeft: '17px' }}>
                                                        <div className="text">Location</div>
                                                        <div>{shift.location} - {shift.postCode}</div>
                                                    </div>
                                                </div>
                                                <div className="line marginBottom"></div>
                                            </div>
                                        </TreeItem>
                                    ))}
                                </TreeItem>
                            </SimpleTreeView>
                        ))}
                    </Box>
                </div>
            </Modal>
            <MapContainer center={coordinates} zoom={9} style={{ height: '92vh', width: '100%', zIndex: 1 }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Button
                    onClick={() => setOpen(true)}
                    className="open-button"
                >
                    <FontAwesomeIcon icon={faMaximize} />
                </Button>
                {positions.map((position: any) => (
                    <Marker position={[position.latitude, position.longitude]} icon={customIcon} eventHandlers={{
                        click: () => getShiftDetails(position.project)
                    }}>
                    </Marker>
                ))}
                <MapController coordinates={coordinates} zoom={zoom}/>
            </MapContainer>
        </>
    )
}

export default Maps;
