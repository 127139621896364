import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Workers from '../EditWorkers/Workers';
import EditShift from '../Modal/EditShift/EditShift';
import { CircularProgress } from '@mui/material';
import { getEditJobModal } from '../../api/apiCall';
import { useAuth } from '../../auth/auth';
import TextEditor from '../TextEditor/TextEditor';
import './Tabs.css';

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ShiftTabs = ({
  id,
  week,
  dateOfTheShift,
  currentCell,
  closeEditModal,
  value,
  setValue,
  shiftData,
  setShiftData
}: any) => {
  const {state} = useAuth();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const GetEditJobModal = async () => {
    try {
      const response = await getEditJobModal(state.user?.token, id)
      setShiftData(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    GetEditJobModal()
  }, [])

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} width={'97%'}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab label="Edit Shift" {...a11yProps(0)} />
          <Tab label="Workers" {...a11yProps(1)} />
          <Tab label="Notes" {...a11yProps(2)} />
          <Tab label="More" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        {!shiftData ? (
          <div className='tab-loading'>
            <CircularProgress />
          </div>
        ) : (
          <EditShift
            id={id}
            week={week}
            shiftData={shiftData}
            dateOfTheShift={dateOfTheShift} 
            currentCell={currentCell}
            getEditJobModal={GetEditJobModal}
          />
        )}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Workers id={id} shiftData={shiftData}/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        {!shiftData ? (
          <div className='tab-loading'>
            <CircularProgress />
          </div>
        ) : (
          <TextEditor id={id} data={shiftData} getEditJobModal={GetEditJobModal} />
        )}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        More
      </CustomTabPanel>
    </Box>
  );
}

export default ShiftTabs;
