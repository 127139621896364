import React from "react";
import MUIRichTextEditor from "mui-rte";
import { useAuth } from "../../auth/auth";
import { saveJobNotes } from "../../api/apiCall";
import { toast } from "react-toastify";
import "./TextEditor.css"

const TextEditor = ({id, data, getEditJobModal}: any) => {
    const {state}  = useAuth();

    const handleSave = async (data: any) => {
        const parsedData = JSON.parse(data)
        let text = parsedData.blocks.map((block: any) => block.text).join(' ');
        if (parsedData.blocks[0].text === "") {
            data = null;
        }
        const response = await saveJobNotes(state.user?.token, id, text? text : null, data);
        if(response) {
            toast.success('Added Notes successfully')
        }else {
            toast.error('Something went wrong!')
        }
        getEditJobModal();
    };

    return (
        <div className="center-text-editor">
            <div className="border-style">
                <MUIRichTextEditor
                    label="Start typing..."
                    inlineToolbar
                    onSave={handleSave}
                    defaultValue={data.notesJson === "null" ? null : data.notesJson}
                />
            </div>
        </div>
    )
}

export default TextEditor;