import { useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useAuth } from "../auth/auth";

const useSignalRConnection = () => {
    const [connection, setConnection] = useState<any>(null);
    const {state} = useAuth();

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://railresourcing.api.pace-is.co.uk/MainHub', {
                accessTokenFactory: () => state.user?.token ? state.user.token : ''
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        const startConnection = async () => {
            try {
                await newConnection.start();
                console.log("SignalR Connected");
                setConnection(newConnection);
            } catch (err) {
                console.log("Error connecting to SignalR", err);
                setTimeout(startConnection, 5000);
            }
        };

        startConnection();

        return () => {
            if (newConnection) {
                newConnection.stop();
                console.log("SignalR Disconnected");
            }
        };
    }, []);

    return connection;
};

export default useSignalRConnection;
