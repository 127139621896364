import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Workers from './pages/Workers/Workers';
import DataTable from './pages/DataTable/DataTable';
import Login from './pages/Login/Login';
import { Graph } from './pages/Graph/Graph';
import Maps from './pages/Maps/Maps';
import OnCall from './pages/OnCall/OnCall';
import { useAuth } from './auth/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { state } = useAuth();

  return (
    <>
      <Routes>
        {state.user?.token && (
          <>
            <Route path="/resourceTracker" element={<Home />} />
            <Route path="/workers" element={<Workers />} />
            <Route path="/table" element={<DataTable />} />
            <Route path="/graph" element={<Graph />} />
            <Route path="/maps" element={<Maps />} />
            <Route path="/onCall" element={<OnCall />} />
            <Route path="*" element={<Navigate to="/resourceTracker" />} />
          </>
        )}
        {!state.user?.token && (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
